@font-face {
  font-family: 'Raleway';
  src: url(./Fonts/Raleway-Black.ttf);
  font-weight: 700;
}

@font-face {
  font-family: 'Raleway';
  src: url(./Fonts/Raleway-BlackItalic.ttf);
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: 'Raleway';
  src: url(./Fonts/Raleway-Bold.ttf);
  font-weight: 600;
}

@font-face {
  font-family: 'Raleway';
  src: url(./Fonts/Raleway-BoldItalic.ttf);
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: 'Raleway';
  src: url(./Fonts/Raleway-Medium.ttf);
  font-weight: 500;
}

@font-face {
  font-family: 'Raleway';
  src: url(./Fonts/Raleway-MediumItalic.ttf);
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: 'Raleway';
  src: url(./Fonts/Raleway-Regular.ttf);
  font-weight: 400;
}

@font-face {
  font-family: 'Raleway';
  src: url(./Fonts/Raleway-Italic.ttf);
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'Raleway';
  src: url(./Fonts/Raleway-Light.ttf);
  font-weight: 300;
}

@font-face {
  font-family: 'Raleway';
  src: url(./Fonts/Raleway-LightItalic.ttf);
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'AccordAlt';
  src: url(./Fonts/AccordAltExtraBold.otf);
  font-weight: 700;
}
@font-face {
  font-family: 'AccordAlt';
  src: url(./Fonts/AccordAltBold.otf);
  font-weight: 600;
}
@font-face {
  font-family: 'AccordAlt';
  src: url(./Fonts/AccordAltMedium.otf);
  font-weight: 500;
}
@font-face {
  font-family: 'AccordAlt';
  src: url(./Fonts/AccordAltRegular.otf);
  font-weight: 400;
}
@font-face {
  font-family: 'AccordAlt';
  src: url(./Fonts/AccordAltLight.otf);
  font-weight: 300;
}
@font-face {
  font-family: 'AccordAlt';
  src: url(./Fonts/AccordAltExtraLight.otf);
  font-weight: 200;
}
@font-face {
  font-family: 'AccordAlt';
  src: url(./Fonts/AccordAltThin.otf);
  font-weight: 100;
}

@font-face {
  font-family: 'Muller';
  src: url(./Fonts/MullerRegular.otf);
}

@font-face {
  font-family: 'MullerThin';
  src: url(./Fonts/MullerThin.otf);
}

@font-face {
  font-family: 'MullerBold';
  src: url(./Fonts/MullerBold.otf);
}

@font-face {
  font-family: 'MullerExtraBold';
  src: url(./Fonts/MullerExtraBold.otf);
}

@font-face {
  font-family: 'MullerHeavy';
  src: url(./Fonts/MullerHeavy.otf);
}

@font-face {
  font-family: 'MullerMedium';
  src: url(./Fonts/MullerMedium.otf);
}
