@import 'src/Themes/utils';
@import 'src/Themes/media-query';
@import 'src/Themes/Constants/fonts.scss';
@import 'src/Themes/Constants/colors.scss';

.fixed-header {
  height: 67px;
  width: 100%;
  position: static;
  box-shadow: 0 3px 6px 1px rgba(0, 0, 0, 0.1);
  background-color: $green3;
  .customer-header {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: space-between;
    &--text {
      display: flex;
      height: 100%;
      justify-content: flex-start;
      gap: 28px;
    }
  }
  .fixed-header__items {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 768px) {
      overflow-x: scroll;
    }

    &--logo {
      content: url(../../Images/Keiken/white-logo.svg);
      width: 120px;
      height: 100%;
      margin: 0;
      margin-right: 100px;
      @media (max-width: 768px) {
        margin-right: 10px;
      }
    }
    &--menu {
      height: 100%;
      font-size: 16px;
      font-weight: 500;
      list-style-type: none;
      display: flex;
      justify-content: space-between;
      flex: 1;
      margin: 0;
      align-items: center;
      // @media (max-width: 450px) {
      //   display: flex;
      //   align-content: space-around;
      //   flex-wrap: nowrap;
      //   overflow-x: scroll;
      //   overflow-y: hidden;
      //   margin-top: 15px;
      //   height: 85px;
      // }
      &--logout {
        margin-left: 30px;

        @media (max-width: 991px) {
          .logout-button {
            display: none;
          }
        }
      }
      li {
        height: 100%;
        display: flex;
        align-items: center;
        &:first-of-type {
          min-width: 92px;
        }
        a {
          color: $white;
          position: relative;
          line-height: 40px;
          display: flex;
          flex-wrap: nowrap;
          align-items: center;
          height: 40px;
          // border-bottom: solid 2px transparent;
          text-decoration: none;
          padding: 0 4px;
          &:hover {
            color: $yellow1;
          }
          &.coming-soon {
            content: "Em Breve";
            position: absolute;
            top: -10px;
          }
          &.link-is-active {
            color: $white;
            font-weight: bold;
          }
          &.link-is-active::before {
            content: "";
            position: absolute;
            top: -16px;
            left: 0;
            height: 10px;
            width: 100%;
            z-index: 100;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            background: $yellow1;
            // @media (max-width: 768px) {
            //   diplay: none;
            // }
          }
        }
      }
    }
  }
}

.fixed-header-container.active .fixed-header {
  background-color: white;
  box-shadow: 0 1px 2px 0 blue;
  .shareholder-fixed-header__items {
    &--menu {
      border-bottom: none;
    }
  }
}

.partner-register-select-image-button-background-outer-class-name {
  width: 530px;
  height: 75px;
  border-radius: 75px;
  background: linear-gradient(to left, #00c87f, #b3ff00);
  position: relative;
  @include responsive(s) {
    width: 100%;
  }
}
