$pumpkin: #ef7d00;
$mango: #f59e24;
$orange-yellow: #f5bc14;
$bland: #b4af8d;
$dark-beige: #918e7a;
$dusty-teal: #4e9a98;
$dark-blue-grey: #1a3c47;
$pumpkin-orange: #f17d00;
$orange: #f1870b;
$tangerine: #fe990b;
$dusty-orange: #f56f3d;
$blue-grey: #9199a1;
$ice-blue: #e8ebec;
$greyish-brown: #4a4a4a;
$white: #fafafa;
$maize: #fdcb41;
$black: #000000;
$white-two: #f6f6f6;
$greyish-teal: #5ab0ae;
$brownish-grey: #707070;
$white-three: #ffffff;
$white-four: #f5f5f5;
$aqua: #0bdad5;
$fern-green: #478b46;
$golden: #f6bd00;
$very-light-pink: #b9b9b9;
$very-light-pink-two: #f0f0f0;
$very-light-pink-three: #eeeeee;
$maize-two: #fecf44;
$nice-blue: #0f74a9;
$black-two: #1c1c1c;
$pale-lilac: #ebebec;
$light-periwinkle: #e1e2e6;
$very-light-pink-four: #e3e3e3;
$brown-grey: #9b9b9b;
$white-five: #fbfbfb;
$beige: #d0c9c9;
$deep-red: #bd0300;
$very-light-pink-five: #e7e7e7;
$brown-grey-two: #a4a4a4;
$charcoal-grey: #363641;
$brown-grey-three: #878787;
$dark-grey: #131415;
$very-light-pink-six: #ebebeb;
$very-light-pink-seven: #d1d1d1;
$purplish-grey: #6e6d6e;
$marine: #072056;
$greyish-teal-two: #61b4b2;
$tangerine-two: #ef8c0a;
$brown-grey-four: #bfbfbf;
$brown-grey-five: #a8aba4;
$error-one: #e04444;
$error-two: #f44336;
$valid-one: #5ab0ae;
$brown-grey-six: #f1f1f1;
$brown-grey-seven: #717171;
$brown-grey-eight: #ababab;
$white-six: #f3f3f3;

$orange: #df6518;
$green: #2d9187;
$darkgreen: #094e51;
$yellow: #f2ae14;
$very-light-pink: #eeeeee;
$very-light-pink-two: #d8d8d8;
$very-light-pink-three: #cacaca;
$bluey-grey: #9199a1;
$brown-grey: #7b7b7b;
$greyblack2: #404040;
$greyblack1: #333333;
$dandelion: #ffe40a;
$very-light-pink-four: #e1e1e1;
$pale-rose: #efc9ce;
$buff: #fceaa2;
$robin-s-egg-blue: #b0e3ed;
$silver: #b9d7bd;
$very-light-pink-five: #dedede;
$pale-salmon: #f4c2a2;
$burnt-sienna: #bd5514;
$burnt-sienna-two: #b25013;

$slate: #455561;
$slate-dark: #1d252b;

$green1: #b3ff00;
$green-label: #00c87f;
$green-calendar: #72dfb7;

$green-succes: #42dd4f;
$red-error: #ff0062;

$gray-placeholder: #8d979e;
$gray-border: #606060;
$gray2: #d6d9db;
$light-gray-border: #f1ecec;
$green-gradient: linear-gradient(to left, #00c87f, #b3ff00);

$green3: #25b379;
$yellow1: #e0fa10;

$red-chart: #fe9da5;
$green-chart: #96dbb6;
$yellow-chart: #fde39e;

$psychology: #c4e99e;
$psychology-subcategory: #6faa32;
$sports: #fdcc9e;
$fitness: #fe9da5;
$motor: #f39ae5;
