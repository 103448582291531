.toast-wrapper {
  width: 252px;
  color: #4a4a4a;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.33;

  * {
    outline: unset !important;
    font-family: MullerMedium, serif !important;
  }

  .Toastify__toast {
    min-height: 64px;
  }

  .Toastify__toast--success {
    // background-color: #ffe40a;
    background-color: #00c87f;
    color: white;

    .Toastify__progress-bar {
      background-color: rgba(100, 89, 4, 0.1);
    }
    .Toastify__close-button {
      color: white;
    }
  }

  .Toastify__toast--error {
    background-color: #efc9ce;

    .Toastify__progress-bar {
      background-color: rgba(94, 79, 81, 0.1);
    }
  }

  .Toastify__close-button {
    color: #4a4a4a;
  }
}
